import React, { useEffect, useRef, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Helmet } from 'react-helmet';
import Masonry from 'react-masonry-css';

import ItemPost from './ItemPost';
import Section from '../common/Section';

import * as cls from './Blog.module.scss';
import './style.scss';

export default function Blog() {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          titleBlog
          descriptionBlog
        }
      }
      allWpPost(sort: { fields: [date] }) {
        nodes {
          title
          slug
          excerpt
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 360
                    placeholder: NONE
                    formats: [AUTO, WEBP]
                  )
                }
              }
            }
          }
        }
      }
    }
  `);

  const allPosts = data.allWpPost.nodes;

  const [list, setList] = useState([...allPosts.slice(0, 10)]);
  const [loadMore, setLoadMore] = useState(false);
  const [hasMore, setHasMore] = useState(allPosts.length > 10);
  const loadRef = useRef();

  const handleObserver = entities => {
    const target = entities[0];
    if (target.isIntersecting) {
      setLoadMore(true);
    }
  };

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '20px',
      threshold: 1.0
    };
    const observer = new IntersectionObserver(handleObserver, options);
    if (loadRef.current) {
      observer.observe(loadRef.current);
    }
  }, []);

  useEffect(() => {
    if (loadMore && hasMore) {
      const currentLength = list.length;
      const isMore = currentLength < allPosts.length;
      const nextResults = isMore
        ? allPosts.slice(currentLength, currentLength + 10)
        : [];
      setList([...list, ...nextResults]);
      setLoadMore(false);
    }
  }, [loadMore, hasMore, allPosts, list]);

  useEffect(() => {
    const isMore = list.length < allPosts.length;
    setHasMore(isMore);
  }, [list, allPosts]);

  const breakpointCols = {
    default: 3,
    960: 2,
    767: 1
  };

  return (
    <>
      <Helmet>
        <title>{data.site.siteMetadata.titleBlog}</title>
        <meta
          name='Description'
          content={data.site.siteMetadata.descriptionBlog}
        />
      </Helmet>

      <Section className={cls.blog} background={true}>
        <Masonry breakpointCols={breakpointCols} className={cls.masonry}>
          {list.map(post => (
            <ItemPost key={post.slug} post={post} />
          ))}
          <div ref={loadRef} />
        </Masonry>
      </Section>
    </>
  );
}
