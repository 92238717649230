import React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image';

import Title from '../../common/Title';

import * as cls from './ItemPost.module.scss';

export default function ItemPost({ post }) {
  return (
    <article className={cls.entry}>
      <div className={cls.entry__thumb}>
        <Link to={`/${post.slug}`}>
          {post.featuredImage?.node?.localFile ? (
            <GatsbyImage
              image={getImage(post.featuredImage.node.localFile)}
              alt={post.title || 'Post image'}
            />
          ) : (
            <StaticImage
              src='../../../images/content/image.png'
              alt={post.title || 'Post image'}
              placeholder='none'
            />
          )}
          <span className='visually-hidden'>{post.title || 'Post title'}</span>
        </Link>
      </div>

      <div className={cls.entry__text}>
        <div>
          <Title type='h3' className={cls.entry__title}>
            <Link to={`/${post.slug}`}>{post.title || 'Post title'}</Link>
          </Title>
        </div>
        <div
          dangerouslySetInnerHTML={{ __html: post.excerpt }}
        />
        <Link className={cls.more} to={`/${post.slug}`}>
          Read more
        </Link>
      </div>
    </article>
  );
}
